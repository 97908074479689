import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import packageJson from '../../../package.json';
import { ROUTES } from '../constants';
import ConnectWalletModal from "../modals/ConnectWalletModal";
import WalletModal from "../modals/WalletModal";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="pp-footer">
        <Container>
          <div className="footer-block">
            <a href="#" className="logo">
              <img src="/images/logo.svg" alt="" />
            </a>
            <div className="footer-block__info">
              <p>Verve socials:</p>
              <div className="footer-block__links">
                <a href="https://t.me/VerveOfficial" target="_blank" className="footer-block__link"><img src="/images/telegram.svg" alt="" /></a>
                <a href="https://t.me/verveappann" target="_blank" className="footer-block__link"><img src="/images/telegram2.svg" alt="" /></a>
                <a href="https://twitter.com/Verve_TV" target="_blank" className="footer-block__link"><img src="/images/twitter.svg" alt="" /></a>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between pp-footer-content">
            <div> Copyright © {currentYear}. All Rights Reserved by Verve</div>
            <div> v{packageJson.version}</div>
            <div className="text-end pp-footer-link">
              <Link target="_blank" to={ROUTES.PRIVACY_POLICY}>Privacy Policy </Link>
              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <Link target="_blank" to={ROUTES.COOKIES_POLICY}>Cookies Policy </Link>
              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <Link target="_blank" to={ROUTES.TERM_OF_USES}>Terms of Use </Link>
            </div>
          </div>
        </Container>
      </div>
      <ConnectWalletModal />
      <WalletModal />
    </>
  );
}