import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import useCopyToClipboard from '../components/CopyToClibboard';
import { BSC_EXPLORER, MODE } from '../../_configs';
import { ACTION_CONST } from '../constants';
import VvButton from '../components/Button';

const WalletModal = (props) => {
    const dispatch = useDispatch();
    const [copied, setCopied] = useCopyToClipboard(1000);
    const walletAddress = useSelector((state) =>
        get(state, "utils.walletAddress", false)
    );
    const handleLogout = () => {
        dispatch({
            type: ACTION_CONST.LOG_OUT_WALLET_SUCCESS
        })
        window.location.reload();
    }

    return (
        <>
            <div className="modal fade " id="walletModal" tabIndex="-1" aria-labelledby="walletModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="walletModalLabel">Your wallet</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-4 text-center">
                                <h5 style={{ fontSize: '22px', wordBreak: 'break-word' }} className="mb-3">{walletAddress}</h5>
                                <a href={`${BSC_EXPLORER[MODE]}/address/${walletAddress}`} target="_blank" className="d-inline-flex align-items-center me-4" style={{ textDecoration: 'none' }}>
                                    <span className="me-1">View on BscScan</span>
                                    <i className="fas fa-fw fa-external-link-alt"></i>
                                </a>
                                <a className="d-inline-flex align-items-center" href="#" onClick={() => setCopied(walletAddress)} style={{ textDecoration: 'none' }}>
                                    <span className="me-1">Copy Address</span>
                                    {!copied ? <i className="far fa-fw fa-copy"></i> : <i className="fas fa-fw fa-check"></i>}
                                </a>
                            </div>
                            <div className="text-center pt-3">
                                <VvButton onClick={handleLogout} className="w-auto mx-auto" data-bs-dismiss="modal">Logout</VvButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WalletModal;


