import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isMobile } from 'web3modal';
import { ACTION_CONST, extensionName } from '../constants';
import { isBinanceExtensionAvailable, isMetamaskAvailable, isTrustWalletAvailable } from '../utils/utils';
import WalletExtensionUtils from "../utils/walletExtensionUtils";
import { toast } from 'react-toastify';
import VvButton from '../components/Button';

const ConnectWalletModal = () => {
    const dispatch = useDispatch();
    const [hasMetamask, setHasMetamask] = useState(false);
    const [hasTrustWallet, setHasTrustWallet] = useState(false);
    const [hasBinanceWallet, setHasBinanceWallet] = useState(false);
    const [modalConnectBSCTitle, setModalBSCConnectTitle] = useState("")

    useEffect(() => {
        setTimeout(() => {
            addWallet();
        }, 3000);
    }, []);
    const addWallet = () => {
        setHasMetamask(isMetamaskAvailable());
        setHasTrustWallet(isTrustWalletAvailable());
        setHasBinanceWallet(isBinanceExtensionAvailable())
        if (isMobile() && !isTrustWalletAvailable() && !isMetamaskAvailable()) {
            dispatch({ type: ACTION_CONST.SET_SHOW_MODAL_HELP })
        }
    };

    const connectWithExtension = async (extensionName) => {
        const temp = new WalletExtensionUtils(extensionName);
        await temp.connect();
        if (temp.checkWrongNetwork()) {
            toast.warn("Wrong network! You need connect to Binance smart chain network!");
            return;
        }

        //Show Block UI
        dispatch({
            type: ACTION_CONST.REQUEST_SUBMIT
        })

        //Disable Block UI
        dispatch({
            type: ACTION_CONST.REQUEST_DONE
        })

        dispatch({
            type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
            data: temp
        })

        // setIsSigning(extensionName);
        await getBalanceAndAddress(temp);

        // iff account exchange
        try {
            temp.accountsChanged(async (res) => {
                if (res !== undefined) {
                    // console.log('account changed')
                    dispatch({
                        type: ACTION_CONST.CLEAR_KYC_STATE
                    })
                    await getBalanceAndAddress(temp);

                }
            });

            return true;
        } catch (e) {
            dispatch({
                type: ACTION_CONST.REQUEST_DONE
            })
            console.log("error: ", e);
        }

        //if chain ID
        try {
            temp.chainChanged(async (chanId) => {
                await temp.connect();
                if (temp.checkWrongNetwork()) {
                    toast.warn("Wrong network! You need connect to Binance smart chain network!");
                    return;
                }
                await getBalanceAndAddress(temp);
            })
        } catch (error) {

        }

    };

    const getExtension = () => {
        return extensionName;
    };

    const getBalanceAndAddress = async (extension) => {
        const walletAddress = await extension.getCurrentAddress();
        dispatch({
            type: ACTION_CONST.CONNECT_WALLET_SUCCESS,
            data: walletAddress
        })
    };


    useEffect(() => {
        if (isMobile() && !hasBinanceWallet && !hasMetamask && !hasTrustWallet) {
            setModalBSCConnectTitle("Please open site in Wallet app")
        }
        else {
            setModalBSCConnectTitle("Connect to wallet")
        }
    }, [isMobile, hasBinanceWallet, hasBinanceWallet, hasTrustWallet])

    return (
        <>
            <div className="modal fade" id="connectWalletModal" tabIndex="-1" aria-labelledby="connectWalletModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="connectWalletModalLabel">{modalConnectBSCTitle}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pb-4">
                            {(hasMetamask || hasBinanceWallet || hasTrustWallet) ? <>
                                {
                                    hasMetamask &&
                                    <VvButton className="w-100 text-nowrap mb-3" data-bs-dismiss="modal" onClick={() => {
                                        connectWithExtension(
                                            getExtension().metamask
                                        )
                                    }}>
                                        <img src="/images/metamask.svg" height="30" width="30" className="me-2" alt="metamask" />
                                        Metamask
                                    </VvButton>
                                }
                                {hasBinanceWallet &&
                                    <VvButton className="w-100 text-nowrap mb-3" data-bs-dismiss="modal"
                                        onClick={() => {
                                            connectWithExtension(
                                                getExtension().binanceExtension
                                            )
                                        }}>
                                        <img src="/images/bsc-icon.svg" height="30" width="30" className="me-2" alt="binance" />
                                        Binance Chain Wallet
                                    </VvButton>
                                }

                                {
                                    hasTrustWallet &&
                                    <VvButton className="w-100 text-nowrap" data-bs-dismiss="modal"
                                        onClick={() => {
                                            connectWithExtension(
                                                getExtension().trustWallet
                                            )
                                        }}>
                                        <img src="/images/trust_platform.png" height="30" width="30" className="me-2" alt="pscpad" />
                                        Trust Wallet
                                    </VvButton>
                                }
                            </> : <>
                                <div className="d-flex align-items-center justify-content-center" style={{ flex: 1, height: '152px' }}>Please wait...</div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConnectWalletModal;


