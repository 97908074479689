import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { BSC_EXPLORER, MODE } from "../../_configs";
import { ACTION_CONST, ROUTES, STATUS } from "../../shared/constants";
import { helpers } from "../../shared/utils";
import StakingPortalPage from "../../shared/layout/StakingPortal";
import { toast } from 'react-toastify';
import VvStepper from "../../shared/components/Stepper";
import VvButton from "../../shared/components/Button";
import { Col, Row } from "react-bootstrap";
import VvCheckpointCard from "../../shared/components/CheckpointCard";

const WithDrawPage = () => {
    const dispatch = useDispatch();
    const [withdrawCurrentStep, setWithdrawCurrentStep] = useState(1);
    const isConnectWallet = useSelector((state) => get(state, "utils.isConnectWallet", false));
    const walletUtils = useSelector((state) => get(state, "utils.walletUtils", null));
    const walletAddress = useSelector((state) => get(state, "utils.walletAddress", ""));
    const stakingInfo = useSelector((state) => get(state, "wallet.stakingInfo", {}));
    const stakingWalletInfo = useSelector((state) => get(state, "wallet.stakingWalletInfo", {}));
    const [acceptTerm, setAcceptTerm] = useState(false);
    const [enableWithDrawBtn, setEnableWithDrawBBtn] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");
    const [bnbBalance, setBnbBalance] = useState(0);
    const [isSubmitOK, setIsSubmitOK] = useState(false);
    const STEPS = ["Checkpoints", "Initialize Withdrawal", "Confirmation"];

    //get Info when connect wallet
    useEffect(() => {
        if (walletUtils && walletAddress) {

            //check bnb balance
            walletUtils.web3.eth.getBalance(walletAddress).then(data => {
                setBnbBalance(data / 10 ** 18)
            })
        }

    }, [walletUtils, walletAddress])

    //enable withdraw button
    useEffect(() => {
        if (acceptTerm && isConnectWallet && bnbBalance > 0 && !stakingInfo["isStakingPaused"] && stakingWalletInfo["withdrawTimestamp"] > 0 && stakingWalletInfo["withdrawTimestamp"] <= (new Date().getTime())) {
            setEnableWithDrawBBtn(true);
        } else {
            setEnableWithDrawBBtn(false);
        }
    }, [acceptTerm, isConnectWallet, bnbBalance, stakingInfo, stakingWalletInfo])


    const withdrawBack = () => {
        if (withdrawCurrentStep == 1) {
            return;
        } else {
            setWithdrawCurrentStep(withdrawCurrentStep - 1);
        }
    }

    const withdrawNext = () => {

        if (withdrawCurrentStep === 2) {
            if (walletUtils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                walletUtils.stakingExecuteWithdrawal(data => {
                    if (data.status === STATUS.STAKING_EXECUTE_WITHDRAWAL_SUCCESS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true)
                        setTransactionHash(data.txID)
                        setWithdrawCurrentStep(3);
                    }
                    if (data.status === STATUS.STAKING_EXECUTE_WITHDRAWAL_FAIL) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        toast.error("Withdraw stake fail.");
                    }
                })
            }
        } else {
            if (withdrawCurrentStep == 3) {
                return;
            } else {
                setWithdrawCurrentStep(withdrawCurrentStep + 1);
            }
        }
    }


    const submitDone = () => {
        setWithdrawCurrentStep(1);
        setIsSubmitOK(false);
    }


    return (
        <StakingPortalPage selectedTab={3}>
            <VvStepper steps={STEPS} currentStep={withdrawCurrentStep} />
            {/* Step 1 */}
            {
                withdrawCurrentStep === 1 ?
                    <div className="vv-step-content" id="WithdrawStep1">
                        <h3 className="vv-step-title">Checkpoints</h3>
                        <p className="vv-step-desc">The following conditions must be met to proceed:</p>
                        <Row>
                            <Col md="6">
                                <VvCheckpointCard active={isConnectWallet} title="Connected with MetaMask" description={`If not connected, click the "Connect Wallet" button in the top right corner`} />
                            </Col>
                            <Col md="6">
                                <VvCheckpointCard active={stakingWalletInfo["withdrawTimestamp"] > 0 && stakingWalletInfo["withdrawTimestamp"] <= (new Date().getTime())} title="7 day waiting period elapsed" description={``} />
                            </Col>
                            <Col md="6">
                                <VvCheckpointCard active={bnbBalance > 0} title="BNB available in wallet" description={`BNB is required to pay transaction fees on the Binance Smart Chain network.<br />BNB Balance: ${helpers.formatNumberDownRoundWithExtractMax(bnbBalance, 4)}`} />
                            </Col>
                        </Row>
                        <div className="mt-4">
                            <div className="form-check text-center">
                                <input className="form-check-input float-none me-1"
                                    type="checkbox" defaultValue id="flexCheckDefault"
                                    onChange={() => setAcceptTerm(!acceptTerm)} />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    &nbsp;I have read the&nbsp; <Link target="_blank" to={ROUTES.TERM_OF_USES}>Terms and Conditions </Link>
                                </label>
                            </div>
                        </div>
                    </div> :
                    <></>
            }

            {/* Step 2 */}
            {
                withdrawCurrentStep === 2 ?
                    <div className="vv-step-content text-center" id="WithdrawStep2">
                        <h3 className="vv-step-title">Confirm Withdrawal</h3>
                        <p className="vv-step-desc">In this step, you complete the transaction that withdraws your <b className="text-white">$VERVE</b> tokens</p>
                    </div> :
                    <></>
            }

            {/* Step 3 */}
            {
                withdrawCurrentStep === 3 ?
                    <div className="vv-step-content text-center" id="WithdrawStep3">
                        <h3 className="vv-step-title">Confirmed</h3>
                        <div className="py-4">
                            <img src="/images/success.png" height={100} alt="" />
                        </div>
                        <p className="vv-step-desc">
                            You have withdrawn your <b className="text-white">$VERVE</b> tokens.<br />
                            If desired, you may check Binance Smart Chain to confirm the transaction.
                        </p>
                        <p><a className="text-white" href={`${BSC_EXPLORER[MODE]}/tx/${transactionHash}`} target="blank">{transactionHash}</a></p>
                    </div> :
                    <></>
            }
            <div className="d-flex justify-content-center pt-5">
                {!isSubmitOK ?
                    <>
                        <VvButton onClick={() => withdrawBack()} disabled={!enableWithDrawBtn || withdrawCurrentStep === 1} className="w-auto me-3">
                            <i className="fas fa-arrow-left me-2" />Previous
                        </VvButton>
                        <VvButton onClick={() => withdrawNext()} disabled={!enableWithDrawBtn || withdrawCurrentStep === 3} className="w-auto">
                            Next<i className="fas fa-arrow-right ms-2" />
                        </VvButton>
                    </>
                    :
                    <>
                        <VvButton onClick={() => submitDone()} className="w-auto">
                            Done
                        </VvButton>
                    </>
                }
            </div>
        </StakingPortalPage>
    );
};

export default WithDrawPage;
