import React from 'react';

export default function VvNotice() {
    return <div className="vv-notice">
        <div className="vv-notice-decor"><img src="/images/blue-round.svg" alt="" /></div>
        <div className="vv-notice-decor"><img src="/images/blue-round.svg" alt="" /></div>
        <div className="vv-notice-decor"><img src="/images/blue-round.svg" alt="" /></div>
        <div className="vv-notice-decor"><img src="/images/blue-round.svg" alt="" /></div>
        <div className="vv-notice-decor"><img src="/images/blue-round.svg" alt="" /></div>
        <i className="fas fa-exclamation-triangle"></i>
        <span>All staking functions are temporarily paused. Please check back again later.</span>
    </div>;
}