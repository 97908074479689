import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { ACTION_CONST, ROUTES, STATUS } from "../../shared/constants";
import { BSC_EXPLORER, STAKING_CONTRACT_ADDRESS, MODE, BSC_VERVE_ADDRESS } from "../../_configs";
import { Row, Col } from "react-bootstrap";
import { BigNumber } from "bignumber.js";
import { helpers } from "../../shared/utils";
import StakingPortalPage from "../../shared/layout/StakingPortal";
import { toast } from 'react-toastify';
import VvButton from "../../shared/components/Button";
import ButtonStaking from "./button";
import VvStepper from "../../shared/components/Stepper";
import VvCheckpointCard from "../../shared/components/CheckpointCard";

const StakingPage = () => {
    const dispatch = useDispatch();
    const isConnectWallet = useSelector((state) => get(state, "utils.isConnectWallet", false));
    const walletUtils = useSelector((state) => get(state, "utils.walletUtils", null));
    const walletAddress = useSelector((state) => get(state, "utils.walletAddress", ""));
    const stakingInfo = useSelector((state) => get(state, "wallet.stakingInfo", {}));
    const stakingWalletInfo = useSelector((state) => get(state, "wallet.stakingWalletInfo", {}));
    const bnbBalance = useSelector((state) => get(state, "wallet.bnbBalance", 0));
    const bscPadBalance = useSelector((state) => get(state, "wallet.bscpadBalance", 0));
    const [acceptTerm, setAcceptTerm] = useState(false);
    const [swapAmount, setSwapAmount] = useState('0')
    const [isMaxAmount, setIsMaxAmount] = useState(false);
    const [swapCurrentStep, setSwapCurrentStep] = useState(1);
    const [enableSwapBtn, setEnableSwapBtn] = useState(false);
    const [transactionHash, setTransactionHash] = useState("")
    const [isSubmitOK, setIsSubmitOK] = useState(false);
    const STEPS = ["Checkpoints", "Amount Stake", "Pre-authorization", "Confirm", "Confirmation"];

    useEffect(() => {
        if (swapCurrentStep === 1) {
            if (acceptTerm &&
                isConnectWallet &&
                !stakingInfo["isStakingPaused"] &&
                parseFloat(bscPadBalance) > 0 &&
                bnbBalance > 0 &&
                stakingWalletInfo["withdrawTimestamp"] === 0
            ) {
                setEnableSwapBtn(true);
            } else {
                setEnableSwapBtn(false);
            }
        }
    }, [acceptTerm, isConnectWallet, bnbBalance, bscPadBalance, stakingInfo, stakingWalletInfo, swapCurrentStep])

    const swapBack = () => {
        if (swapCurrentStep === 1) {
            return;
        } else {
            setSwapCurrentStep(swapCurrentStep - 1);
        }
    }

    const swapNext = async () => {
        if (swapCurrentStep === 5) return;
        if (swapCurrentStep === 1 && parseFloat(swapAmount) === 0) {
            setEnableSwapBtn(false);
            setSwapCurrentStep(swapCurrentStep + 1);
            return;
        }

        if (swapCurrentStep === 3) {
            // click approve
            if (walletUtils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                });
                let amount = isMaxAmount ? bscPadBalance : swapAmount
                //check allowance 
                const allowance = await walletUtils.getAllowance(BSC_VERVE_ADDRESS[MODE], STAKING_CONTRACT_ADDRESS[MODE]);

                if (BigNumber(allowance).gte(BigNumber(amount))) {
                    setSwapCurrentStep(4);
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE,
                    });
                    return;
                }

                // debugger
                walletUtils.approve({ tokenContractAddress: stakingInfo["tokenAddr"], contractAddress: STAKING_CONTRACT_ADDRESS[MODE], amount: amount }, (data) => {
                    //function success
                    if (data.status === STATUS.APPROVED) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        });
                        toast.success("Approve Tokens successfully!");
                        setSwapCurrentStep(4);
                    }
                    //function fails
                    if (data.status === STATUS.APPROVE_FAILS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        toast.error("Failed to Approve Tokens!");
                    }
                })
            }
        } else if (swapCurrentStep === 4) {
            // click deposit
            if (walletUtils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                walletUtils.stakingDeposit({ amount: isMaxAmount ? bscPadBalance : swapAmount }, (result) => {
                    if (result.status === STATUS.STAKING_DEPOSIT_SUCCESS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true)
                        setTransactionHash(result.txID)

                        //change to result screen
                        setSwapCurrentStep(5);

                    }
                    if (result.status === STATUS.STAKING_DEPOSIT_FAIL) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        toast.error("Deposit stake fail!");
                    }
                })
            }
        } else {
            setSwapCurrentStep(swapCurrentStep + 1);
        }
    }


    //handle input swap change
    const handleInputSwap = (e) => {
        setIsMaxAmount(false);

        if (helpers.isFloatFormatted(e.target.value, 4)) {
            setSwapAmount(e.target.value);

            //check input
            if (BigNumber(e.target.value).lte(BigNumber(bscPadBalance)) && BigNumber(e.target.value).gt(BigNumber(0))) {
                setEnableSwapBtn(true);
            } else {
                setEnableSwapBtn(false);
            }
        }
    }

    const submitDone = () => {
        setSwapAmount('0');
        setIsMaxAmount(true);
        setSwapCurrentStep(1);
        setIsSubmitOK(false);
    }

    const handleMaxButtonClick = () => {
        setSwapAmount(helpers.formatNumberDownRound(bscPadBalance, 4));
        setEnableSwapBtn(true);
        setIsMaxAmount(true);
    }

    return (
        <StakingPortalPage selectedTab={1}>
            <VvStepper steps={STEPS} currentStep={swapCurrentStep} />
            {/* Step 1 */}
            {
                swapCurrentStep === 1 ?
                    <div className="vv-step-content" id="SwapStep1">
                        <h3 className="vv-step-title">Checkpoints</h3>
                        <p className="vv-step-desc">The following conditions must be met to proceed:</p>
                        <Row>
                            <Col md="6">
                                <VvCheckpointCard active={isConnectWallet} title="Connected with MetaMask" description={`If not connected, click the "Connect Wallet" button in the top right corner`} />
                            </Col>
                            <Col md="6">
                                <VvCheckpointCard active={parseFloat(bscPadBalance) > 0} title="$VERVE available to deposit" description={`Current Balance: ${helpers.formatNumberDownRoundWithExtractMax(bscPadBalance, 4)}`} />
                            </Col>
                            <Col md="6">
                                <VvCheckpointCard active={bnbBalance > 0} title="BNB available in wallet" description={`BNB is required to pay transaction fees on the Binance Smart Chain network.<br />BNB Balance: ${helpers.formatNumberDownRoundWithExtractMax(bnbBalance, 4)}`} />
                            </Col>
                            <Col md="6">
                                <VvCheckpointCard active={stakingWalletInfo["withdrawTimestamp"] === 0} title="Eligible to stake" description={`You cannot stake if you have an active VERVE unstake/withdrawal request`} />
                            </Col>
                        </Row>
                        <div className="mt-4">
                            <div className="form-check text-center">
                                <input className="form-check-input float-none me-1"
                                    type="checkbox" defaultValue id="flexCheckDefault"
                                    onChange={() => setAcceptTerm(!acceptTerm)} />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    &nbsp;I have read the&nbsp; <Link target="_blank" to={ROUTES.TERM_OF_USES}>Terms and Conditions </Link>
                                </label>
                            </div>
                        </div>
                    </div> :
                    <></>
            }

            {/* Step 2 */}
            {
                swapCurrentStep === 2 ?
                    <div className="vv-step-content" id="SwapStep2">
                        <h3 className="vv-step-title">Amount Stake</h3>
                        <p className="vv-step-desc">Please enter the amount of <b className="text-white">$VERVE</b> you want to stake</p>
                        <div className="vv-input-group mb-3 mx-auto">
                            <input type="number" className="form-control" placeholder={0.0} value={swapAmount} onChange={(e) => handleInputSwap(e)} />
                            <VvButton onClick={handleMaxButtonClick}>
                                MAX
                            </VvButton>
                        </div>
                        <div className="vv-balance text-center">
                            Your balance: <b>{helpers.formatNumberDownRound(bscPadBalance, 4)}</b>
                        </div>
                    </div> :
                    <></>
            }

            {/* Step 3 */}
            {
                swapCurrentStep === 3 ?
                    <div className="vv-step-content text-center" id="SwapStep3">
                        <h3 className="vv-step-title">Pre-authorization</h3>
                        <p className="vv-step-desc">
                            <i>(Required transaction 1 of 2)</i><br />
                            In this step, you grant access to the staking smart contract to accept your <b className="text-white">$VERVE</b></p>
                        <div className="mt-4">
                            <div className="p-progress-waiting">
                                <div className="p-progress-percent" style={{ width: '60%' }} />
                            </div>
                            <h4 className="mb-2 pb-1">Waiting for the transaction to complete</h4>
                            <p>Please wait for the transaction to confirm before proceeding.</p>
                            <p><a className="text-white" href={`${BSC_EXPLORER[MODE]}/address/${walletAddress}`} target="blank">{walletAddress}</a></p>
                        </div>
                    </div> :
                    <></>
            }

            {/* Step 4 */}
            {
                swapCurrentStep === 4 ?
                    <div className="vv-step-content text-center" id="SwapStep4">
                        <h3 className="vv-step-title">Confirm</h3>
                        <p className="vv-step-desc">
                            <i>(Required transaction 2 of 2)</i><br />
                            In this step, you deposit the tokens into the staking contract.<br />
                            After this step, your tokens will be successfully staked.
                        </p>
                    </div> :
                    <></>
            }

            {/* Step 5 */}
            {
                swapCurrentStep === 5 ?
                    <div className="vv-step-content text-center" id="SwapStep5">
                        <h3 className="vv-step-title">Confirmation</h3>
                        <div className="py-4">
                            <img src="/images/success.png" height={100} alt=""/>
                        </div>
                        <p className="vv-step-desc">
                            Congratulations! Your tokens are now staked.<br />
                            If desired, you may check Binance Smart Chain to confirm the transaction.
                        </p>
                        <p><a className="text-white" href={`${BSC_EXPLORER[MODE]}/tx/${transactionHash}`} target="blank">{transactionHash}</a></p>
                    </div> :
                    <></>
            }
            <ButtonStaking isSubmitOK={isSubmitOK} swapCurrentStep={swapCurrentStep} enableSwapBtn={enableSwapBtn} swapBack={swapBack} swapNext={swapNext} submitDone={submitDone} />
        </StakingPortalPage>
    );
};

export default StakingPage;
