import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import exactMath from 'exact-math';
import { ACTION_CONST, ROUTES } from "../constants";
import { helpers } from "../../shared/utils";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import VvButton from "./Button";
import $ from 'jquery';

const Header = () => {
  const dispatch = useDispatch();
  const isConnectWallet = useSelector((state) => get(state, "utils.isConnectWallet", false));
  const walletAddress = useSelector((state) => get(state, "utils.walletAddress", false));
  const walletUtils = useSelector((state) => get(state, "utils.walletUtils", null));
  const [bscpadBalance, setBscpadBalance] = useState(0);
  const [init, setInit] = useState(true);

  //set balance
  useEffect(() => {
    if (walletUtils && walletAddress) {

      //get bnb balance
      walletUtils.web3.eth.getBalance(walletAddress).then(balance => {

        dispatch({
          type: ACTION_CONST.GET_BNB_BALANCE,
          data: exactMath.div(balance, 10 ** 18)
        })
      }).catch(err => {
        console.log(err);
      })

      walletUtils.getTokenStakingBalance().then(data => {

        dispatch({
          type: ACTION_CONST.GET_VERVE_WALLET_BALANCE,
          data: data
        })
        setBscpadBalance(data)
      })

      //get staking wallet Info
      walletUtils.getStakingInfoWallet().then(data => {
        // debugger      
        dispatch({ type: ACTION_CONST.GET_STAKING_WALLET_INFO, data: data })
      });


      //Set interval
      if (init) {
        setInit(false)
        const jobGetBalance = setInterval(() => {
          //get bnb balance
          walletUtils.web3.eth.getBalance(walletAddress).then(balance => {
            dispatch({
              type: ACTION_CONST.GET_BNB_BALANCE,
              data: exactMath.div(balance, 10 ** 18)
            })
          })

          //get bscPad balance
          walletUtils.getTokenStakingBalance().then(data => {
            dispatch({
              type: ACTION_CONST.GET_VERVE_WALLET_BALANCE,
              data: data
            })

            setBscpadBalance(data)
          })

        }, 10 * 1000)
        dispatch({ type: ACTION_CONST.SET_JOB_GET_BALANCE, data: jobGetBalance })
      }
    }

  }, [walletAddress, walletUtils]);

  const handleTabChange = () => {
    $('.navbar-toggler').trigger('click');
  }

  return (
    <Navbar fixed="top" id="PPNavbar" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to={ROUTES.STACKING}>
          <Image src="/images/logo.svg" height="39" alt="Verve" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="AppNavbar" />
        <Navbar.Collapse id="AppNavbar">
          <Nav className="mx-auto">
            <Nav.Link as={NavLink} activeClassName="active" to={ROUTES.STACKING} onClick={handleTabChange} >
              Stake
            </Nav.Link>
            <Nav.Link as={NavLink} activeClassName="active" to={ROUTES.UNSTACKING} onClick={handleTabChange} >
              Unstake
            </Nav.Link>
            <Nav.Link as={NavLink} activeClassName="active" to={ROUTES.WITHDRAW} onClick={handleTabChange} >
              Withdraw
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto">
            {
              !isConnectWallet ? <Nav.Item>
                <VvButton data-bs-toggle="modal" data-bs-target={"#connectWalletModal"}>
                  Connect Wallet
                </VvButton>
              </Nav.Item> :
                <Nav.Item>
                  <VvButton data-bs-toggle="modal" data-bs-target="#walletModal">
                    {helpers.formatTransactionHash(walletAddress, 4, 4)} - {helpers.formatNumberDownRoundWithExtractMax(bscpadBalance, 4)} VERVE
                  </VvButton>
                </Nav.Item>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
