import React from "react";
import { Router, Switch, Redirect } from "react-router-dom";
import { history } from "./shared/utils/history";
import { PrivateRoute } from "./shared/routes/PrivateRoute";
import { ROUTES } from "./shared/constants";
import PrivacyPolicyPage from "./pages/privacy-policy";
import CookiesPolicyPage from "./pages/cookies-policy";
import TermOfUsePage from "./pages/terms-conditions";
import StakingPage from "./pages/staking";
import UnStakingPage from "./pages/unstaking";
import WithDrawPage from "./pages/withdraw";
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-block-ui/style.css";
import "./style/core.scss";

const App = () => {
  return (
    <>
      <Router history={history}>
        <Switch>
          <PrivateRoute
            exact
            path={ROUTES.STACKING}
            component={StakingPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.UNSTACKING}
            component={UnStakingPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.WITHDRAW}
            component={WithDrawPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.PRIVACY_POLICY}
            component={PrivacyPolicyPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.COOKIES_POLICY}
            component={CookiesPolicyPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.TERM_OF_USES}
            component={TermOfUsePage}
          />
          <Redirect from='**' to={ROUTES.STACKING} />
        </Switch>
      </Router>
      <ToastContainer />
    </>
  );
};

export default App;
