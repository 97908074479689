import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { getStakingContractInfo } from "../utils/bscHelpers";
import { helpers } from "../utils";
import { ACTION_CONST, STATUS } from "../constants";
import { changeTab } from "../../redux/action/app";
import { Container, Row, Col } from "react-bootstrap";
import VvDecor from "../components/Decor";
import VvNotice from "../components/Notice";
import { toast } from 'react-toastify';
import { getCountDown } from "../utils/helper";
import VvCard from "../components/Card";
import VvButton from "../components/Button";

const StakingPortalPage = ({ selectedTab, children }) => {
    const dispatch = useDispatch();
    const [stakerCount, setStakerCount] = useState(0);
    const [totalStakedAmount, setTotalStakedAmount] = useState(0);
    const [apyPercentage, setApyPercentage] = useState(0);
    const [withdrawTimestamp, setWithdrawTimestamp] = useState(0);
    const [unstakedAmount, setUnstakedAmount] = useState('0');
    const [rewardAmount, setRewardAmount] = useState('0');
    const [stakedAmount, setStakedAmount] = useState('0');
    const walletAddress = useSelector((state) => get(state, "utils.walletAddress", ""));
    const walletUtils = useSelector((state) => get(state, "utils.walletUtils", null));
    const stakingInfo = useSelector((state) => get(state, "wallet.stakingInfo", {}));
    const isConnectWallet = useSelector((state) => get(state, "utils.isConnectWallet", false));
    const submitOK = useSelector((state) =>
        get(state, "utils.blocking", false)
    );

    useEffect(() => {
        dispatch(changeTab(1));
    }, [])

    useEffect(() => {
        try {
            getStakingContractInfo().then(data => {
                setStakerCount(data.stakerCount);
                setTotalStakedAmount(data.totalStakedAmount);
                setApyPercentage(data.apyPercentage);
                dispatch({ type: ACTION_CONST.GET_STAKING_INFO, data: data });
            })
        } catch (error) {
            console.log(error);
        }

        //setup interval
        const job = setInterval(() => {
            getStakingContractInfo().then(data => {
                setStakerCount(data.stakerCount);
                setTotalStakedAmount(data.totalStakedAmount);
                setApyPercentage(data.apyPercentage);
                dispatch({ type: ACTION_CONST.GET_STAKING_INFO, data: data });
            })
        }, 10 * 1000);
        dispatch({ type: ACTION_CONST.SET_JOB_GET_STAKING_INFO, job });
    }, [dispatch, submitOK]);

    useEffect(() => {
        if (walletUtils && walletAddress) {
            walletUtils.getStakingInfoWallet().then(data => {
                setStakedAmount(data.stakedAmount);
                setUnstakedAmount(data.unstakedAmount);
                setRewardAmount(data.rewardAmount);
                const now = new Date().getTime();
                if (data.withdrawTimestamp > now) {
                    setWithdrawTimestamp(data.withdrawTimestamp);
                }
                dispatch({ type: ACTION_CONST.GET_STAKING_WALLET_INFO, data: data })
            });

            //setup interval
            const job = setInterval(() => {
                walletUtils.getStakingInfoWallet().then(data => {
                    setStakedAmount(data.stakedAmount);
                    setUnstakedAmount(data.unstakedAmount);
                    setRewardAmount(data.rewardAmount);
                    const now = new Date().getTime();
                    if (data.withdrawTimestamp > now && data.withdrawTimestamp !== withdrawTimestamp) {
                        setWithdrawTimestamp(data.withdrawTimestamp);
                    }
                    dispatch({ type: ACTION_CONST.GET_STAKING_WALLET_INFO, data: data })
                })
            }, 10 * 1000);

            dispatch({ type: ACTION_CONST.SET_JOB_GET_STAKING_WALLET_INFO, job });
        }
    }, [walletUtils, walletAddress, submitOK, dispatch, withdrawTimestamp])

    useEffect(() => {
        if (withdrawTimestamp > 0) {
            getCountDown(`${walletAddress}-endtime`, withdrawTimestamp, (job) => {

                dispatch({
                    type: ACTION_CONST.SET_JOB_COUNTDOWN_STAKE_TIME,
                    data: job
                });

            }, (job) => {
                setWithdrawTimestamp(0);
            });
        }
    }, [dispatch, walletAddress, withdrawTimestamp])

    //handle button withdraw click 
    const handleExcWithDraw = () => {
        if (walletUtils) {
            dispatch({
                type: ACTION_CONST.REQUEST_SUBMIT
            })
            walletUtils.stakingExecuteWithdrawRewards(data => {
                if (data.status === STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_SUCCESS) {
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE
                    })
                    toast.success("Withdraw stake successfully.");
                }
                if (data.status === STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL) {
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE
                    })
                    toast.error("Withdraw stake fail.");
                }
            })
        }
        //to do somrthing
    }

    //stake reward
    const handleExStakeRewards = async () => {
        if (walletUtils) {
            dispatch({
                type: ACTION_CONST.REQUEST_SUBMIT
            })
            walletUtils.stakingRewards(data => {
                if (data.status === STATUS.STAKING_REWARDS_SUCCESS) {
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE
                    })
                    toast.success("Stake rewards successfully.");
                }
                if (data.status === STATUS.STAKING_REWARDS_FAIL) {
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE
                    })
                    toast.error("Stake rewards fail.");
                }
            })
        }
    }

    return (
        <div className={`vv-layout ${stakingInfo["isStakingPaused"] ? 'vv-pending' : ''}`}>
            <VvDecor />
            <VvDecor className="bottom-decor" />
            <Container>
                {stakingInfo["isStakingPaused"] ? <VvNotice /> : <></>}
                <Row>
                    <Col md="4">
                        <VvCard title="Number of Stakers" value={stakerCount} />
                    </Col>
                    <Col md="4">
                        <VvCard title="Total VERVE Staked" value={helpers.formatNumberDownRound(totalStakedAmount, 2)} />
                    </Col>
                    <Col md="4">
                        <VvCard title="APY" value={`${helpers.formatNumberDownRound((apyPercentage / 100).toString(), 2)}%`} />
                    </Col>
                    {isConnectWallet ? <>
                        <Col md="4">
                            <VvCard className="style-1" title="Your Staked" value={helpers.formatNumberDownRoundWithExtractMax(stakedAmount, 4)} />
                        </Col>
                        <Col md="4">
                            <VvCard className="style-2" title="Your Unstaked" value={helpers.formatNumberDownRoundWithExtractMax(unstakedAmount, 4)}>
                                {parseFloat(unstakedAmount) > 0 && withdrawTimestamp > 0 && (
                                    <>
                                        Withdrawable in:
                                        <div className="vv-card-title" id={`${walletAddress}-endtime`}></div>
                                    </>
                                )}
                                {parseFloat(unstakedAmount) > 0 && withdrawTimestamp === 0 && (<>Withdrawable Now</>)}
                            </VvCard>
                        </Col>
                        <Col md="4">
                            <VvCard className="style-3" title="Your Rewards" value={helpers.formatNumberDownRoundWithExtractMax(rewardAmount, 4)} >
                                <div className="vv-card-actions">
                                    <VvButton size="sm"
                                        disabled={!isConnectWallet || parseFloat(rewardAmount) === 0 || parseFloat(unstakedAmount) > 0}
                                        onClick={() => handleExStakeRewards()}>
                                        Stake
                                    </VvButton>
                                    <VvButton size="sm"
                                        disabled={!isConnectWallet || parseFloat(rewardAmount) === 0 || parseFloat(unstakedAmount) > 0}
                                        onClick={() => handleExcWithDraw()}>
                                        Withdraw
                                    </VvButton>
                                </div>
                            </VvCard>
                        </Col>
                    </> : <></>
                    }
                </Row>
                {selectedTab == 1 && <h4 className="vv-page-title">Stake your <span>$VERVE</span></h4>}
                {selectedTab == 2 && <h4 className="vv-page-title">Unstake your <span>$VERVE</span></h4>}
                {selectedTab == 3 && <h4 className="vv-page-title">Withdraw your <span>$VERVE</span></h4>}
                <div className="vv-layout-inner">
                    {children}
                </div>
            </Container>
        </div>
    );
};

export default StakingPortalPage;
