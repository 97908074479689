// console.log("evn",process.env.NODE_ENV);
// if (process.env.NODE_ENV === 'production') {
//     //we are in production-return product key!
//     module.exports = require('./prod-config');
// } else {
//     //we are in dev-return dev key!
//     module.exports = require('./dev-config');
// }

export const MODE = process.env.REACT_APP_MODE || 'TESTNET';
export const BSC_EXPLORER = {
    MAINNET: 'https://bscscan.com',
    TESTNET: 'https://testnet.bscscan.com'
}

export const BSC_NODE_URL = {
    MAINNET: [
        "https://bsc-dataseed1.ninicoin.io/",
        "https://bsc-dataseed.binance.org/",
        "https://bsc-dataseed1.defibit.io/",
    ],
    TESTNET: ['https://data-seed-prebsc-1-s2.binance.org:8545/']
}

export const STAKING_CONTRACT_ADDRESS = {
    MAINNET: '0x0b4C4fED97a121d4437BFA8E02f2b0686bE0Efff',
    TESTNET: '0xe0b0C16038845BEd3fCf70304D3e167Df81ce225'
}
export const BSC_VERVE_ADDRESS = {
    MAINNET: '0x32561fa6d2d3e2191bf50f813df2c34fb3c89b62',
    TESTNET: '0xe0d24e7695fd9dcc57a097388811fbaeece68ce7'
}

// export const chainId