import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { ACTION_CONST, ROUTES, STATUS } from "../../shared/constants";
import { BSC_EXPLORER, MODE } from "../../_configs";
import { Col, Row } from "react-bootstrap";
import { BigNumber } from "bignumber.js";
import { helpers } from "../../shared/utils";
import StakingPortalPage from "../../shared/layout/StakingPortal";
import { toast } from 'react-toastify';
import VvStepper from "../../shared/components/Stepper";
import VvCheckpointCard from "../../shared/components/CheckpointCard";
import VvButton from "../../shared/components/Button";

const UnStakingPage = () => {
    const dispatch = useDispatch();
    const [unStakeCurrentStep, setUnStakeCurrentStep] = useState(1);
    const isConnectWallet = useSelector((state) => get(state, "utils.isConnectWallet", false));
    const walletUtils = useSelector((state) => get(state, "utils.walletUtils", null));
    const stakingInfo = useSelector((state) => get(state, "wallet.stakingInfo", {}));
    const stakingWalletInfo = useSelector((state) => get(state, "wallet.stakingWalletInfo", {}));
    const bnbBalance = useSelector((state) => get(state, "wallet.bnbBalance", 0));
    const [acceptTerm, setAcceptTerm] = useState(false);
    const [enableUnStakeBtn, setEnableUnStakeBtn] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");
    const [unStakeAmount, setUnStakeAmount] = useState('0');
    const [isMaxAmount, setIsMaxAmount] = useState(false);
    const [isSubmitOK, setIsSubmitOK] = useState(false);
    const STEPS = ["Warning", "Checkpoints", "Amount to Unstake", "Initialize Unstake", "Confirmation"];

    //enable unstake button
    useEffect(() => {
        if (unStakeCurrentStep === 2) {
            if (acceptTerm &&
                isConnectWallet &&
                !stakingInfo["isStakingPaused"] &&
                parseFloat(stakingWalletInfo["stakedAmount"]) > 0 &&
                bnbBalance > 0 &&
                stakingWalletInfo["withdrawTimestamp"] === 0
            ) {
                setEnableUnStakeBtn(true);
            } else {
                setEnableUnStakeBtn(false);
            }
        }
        if (unStakeCurrentStep === 1) setEnableUnStakeBtn(true);
    }, [acceptTerm, isConnectWallet, bnbBalance, stakingInfo, stakingWalletInfo, unStakeCurrentStep])


    const unStakeBack = () => {
        if (unStakeCurrentStep === 1) {
            return;
        } else {
            setUnStakeCurrentStep(unStakeCurrentStep - 1);
        }
    }

    const unStakeNext = () => {
        if (unStakeCurrentStep === 1) {
            setEnableUnStakeBtn(false);
            setUnStakeCurrentStep(unStakeCurrentStep + 1);
            return;
        }

        if (unStakeCurrentStep === 2 && parseFloat(unStakeAmount) === 0) {
            setEnableUnStakeBtn(false);
            setUnStakeCurrentStep(unStakeCurrentStep + 1);
            return;
        }

        if (unStakeCurrentStep === 4) {
            if (walletUtils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                walletUtils.stakingInitiateWithdrawal({ amount: isMaxAmount ? stakingWalletInfo["stakedAmount"] : unStakeAmount }, data => {
                    // console.log(data);
                    if (data.status === STATUS.STAKING_INITIATE_WITHDRAWAL_SUCCESS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true);
                        setTransactionHash(data.txID);

                        setUnStakeCurrentStep(5);

                    }
                    if (data.status === STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })

                        toast.error("INIT Unstake fail.");
                    }
                })
            }
        } else {
            if (unStakeCurrentStep === 5) {
                return;
            } else {
                setUnStakeCurrentStep(unStakeCurrentStep + 1);
            }
        }
    }

    const handleInputUnStake = (e) => {
        setIsMaxAmount(false);

        if (helpers.isFloatFormatted(e.target.value, 4)) {
            setUnStakeAmount(e.target.value);

            //check input
            if (BigNumber(e.target.value).lte(BigNumber(stakingWalletInfo["stakedAmount"])) && BigNumber(e.target.value).gt(BigNumber(0))) {
                setEnableUnStakeBtn(true);
            } else {
                setEnableUnStakeBtn(false);
            }
        }
    }

    const handleMaxButtonClick = () => {
        setUnStakeAmount(helpers.formatNumberDownRound(stakingWalletInfo["stakedAmount"], 4));
        setEnableUnStakeBtn(true);
        setIsMaxAmount(true);
    }


    const submitDone = () => {
        setUnStakeAmount('0');
        setIsMaxAmount(true);
        setUnStakeCurrentStep(1);
        setIsSubmitOK(false);
    }

    return (
        <StakingPortalPage selectedTab={2}>
            <VvStepper steps={STEPS} currentStep={unStakeCurrentStep} />
            {/* Step 1 */}
            {
                unStakeCurrentStep === 1 ?
                    <div className="vv-step-content text-center" id="UnStakeStep1">
                        <h3 className="vv-step-title">Warning</h3>
                        <div className="py-4">
                            <img src="/images/warning.png" height={100} alt="" />
                        </div>

                        <p className="vv-step-desc text-warning">After Unstaking, you must wait 7 days before you can withdraw your <b>$VERVE</b> and rewards.</p>
                    </div> :
                    <></>
            }
            {/* Step 2 */}
            {
                unStakeCurrentStep === 2 ?
                    <div className="vv-step-content" id="UnStakeStep2">
                        <h3 className="vv-step-title">Checkpoints</h3>
                        <p className="vv-step-desc">The following conditions must be met to proceed:</p>
                        <Row>
                            <Col md="6">
                                <VvCheckpointCard active={isConnectWallet} title="Connected with MetaMask" description={`If not connected, click the "Connect Wallet" button in the top right corner`} />
                            </Col>
                            <Col md="6">
                               
                                <VvCheckpointCard active={parseFloat(stakingWalletInfo["stakedAmount"]) > 0} title="Have an active $VERVE stake" description={`You currently have ${helpers.formatNumberDownRound(stakingWalletInfo["stakedAmount"], 2)} $VERVE staked`} />
                            </Col>
                            <Col md="6">
                                <VvCheckpointCard active={bnbBalance > 0} title="BNB available in wallet" description={`BNB is required to pay transaction fees on the Binance Smart Chain network.<br />BNB Balance: ${helpers.formatNumberDownRoundWithExtractMax(bnbBalance, 4)}`} />
                            </Col>
                            <Col md="6">
                               
                                <VvCheckpointCard active={stakingWalletInfo["withdrawTimestamp"] === 0} title="Eligible to initiate unstake" description={`You cannot unstake if you already have an active <b className="text-white">$VERVE</b> unstake/withdrawal request`} />
                            </Col>
                        </Row>
                        <div className="mt-4">
                            <div className="form-check text-center">
                                <input className="form-check-input float-none me-1"
                                    type="checkbox" defaultValue id="flexCheckDefault"
                                    onChange={() => setAcceptTerm(!acceptTerm)} />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    &nbsp;I have read the&nbsp;
                                    <Link target="_blank" to={ROUTES.TERM_OF_USES}>Terms and Conditions </Link>
                                </label>
                            </div>
                        </div>
                    </div> :
                    <></>
            }

            {/* Step 3 */}
            {
                unStakeCurrentStep === 3 ?
                    <div className="vv-step-content" id="UnStakeStep3">
                        <h3 className="vv-step-title">Amount to Unstake</h3>
                       
                        <p className="vv-step-desc">Please enter the amount of <b className="text-white">$VERVE</b> you want to unstake</p>
                        
                        <div className="vv-input-group mb-3 mx-auto">
                            <input type="number" className="form-control" placeholder={0.0} value={unStakeAmount} onChange={(e) => handleInputUnStake(e)} />
                            <VvButton onClick={handleMaxButtonClick}>
                                MAX
                            </VvButton>
                        </div>
                    </div> :
                    <></>
            }

            {/* Step 4 */}
            {
                unStakeCurrentStep === 4 ?
                    <div className="vv-step-content text-center" id="UnStakeStep4">
                        <h3 className="vv-step-title">Confirm Unstaking Process</h3>
                        
                        <p className="vv-step-desc">In this step, you initiate the unstaking process.<br/>After a 7 day waiting period, you will be allowed to withdraw your <b className="text-white">$VERVE</b></p>

                    </div> :
                    <></>
            }

            {/* Step 5 */}
            {
                unStakeCurrentStep === 5 ?
                    <div className="vv-step-content text-center" id="UnStakeStep5">
                        <h3 className="vv-step-title">Confirmed</h3>
                        <div className="py-4">
                            <img src="/images/success.png" height={100} alt="" />
                        </div>
                        <p className="vv-step-desc">
                            You have initiated the unstaking process and started the 7 day timer.<br />
                            If desired, you may check Binance Smart Chain to confirm the transaction.
                        </p>
                        <p><a className="text-white" href={`${BSC_EXPLORER[MODE]}/tx/${transactionHash}`} target="blank">{transactionHash}</a></p>
                    </div> :
                    <></>
            }

            <div className="d-flex justify-content-center pt-5">
                {!isSubmitOK ?
                    <>
                        <VvButton onClick={() => unStakeBack()} disabled={!enableUnStakeBtn || unStakeCurrentStep === 1} className="w-auto me-3">
                          
                            <i className="fas fa-arrow-left me-2" />Previous
                        </VvButton>
                        <VvButton onClick={() => unStakeNext()} disabled={!enableUnStakeBtn || unStakeCurrentStep === 5} className="w-auto">
                      
                            Next<i className="fas fa-arrow-right ms-2" />
                        </VvButton>
                    </>
                    :
                    <>
                        <VvButton onClick={() => submitDone()} className="w-auto">
                            Done
                        </VvButton>
                    </>
                }
            </div>
        </StakingPortalPage>
    );
};

export default UnStakingPage;
