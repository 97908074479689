import React from 'react';
import VvButton from "../../shared/components/Button";

const ButtonStaking = ({ isSubmitOK, swapCurrentStep, enableSwapBtn, swapBack, swapNext, submitDone }) => {
    return (
        <div className="d-flex justify-content-center pt-5">
            {
                !isSubmitOK ?
                    <>
                        <VvButton onClick={() => swapBack()} disabled={!enableSwapBtn || swapCurrentStep === 1} className="w-auto me-3">
                            <i className="fas fa-arrow-left me-2" />Previous
                        </VvButton>
                        <VvButton onClick={() => swapNext()} disabled={!enableSwapBtn || swapCurrentStep === 5} className="w-auto">
                            Next<i className="fas fa-arrow-right ms-2" />
                        </VvButton>
                    </>
                    :
                    <>
                        <VvButton onClick={() => submitDone()} className="w-auto">Done</VvButton>
                    </>
            }
        </div>
    )
}
export default ButtonStaking;