import React from 'react';

export default function VvCard(props) {
    return <div className={`vv-card ${props.className || ''}`}>
        <div className="vv-card-title">{props.title}</div>
        <div className="vv-card-body">
            <span className="vv-card-value">{props.value}</span>
            <div>{props.children}</div>
        </div>
    </div>;
}