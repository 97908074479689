import React from 'react';

export default function VvStepper({ steps, currentStep }) {
    return (
        <div className="vv-stepper__progress">
            {steps.map((step, idx) => {
                return <button key={idx} className={`vv-stepper__progress-btn ${currentStep > (idx + 1) ? 'completed' : ''} ${(idx + 1) === currentStep ? 'active' : ''}`} type="button" title={step}>{step}</button>
            })}
        </div>
    )
}