import React from 'react';
import { Button } from 'react-bootstrap';

export default function VvButton(props) {
    return (
        <Button variant="primary" {...props}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            {props.children}
        </Button>
    );
}